import { ProductsProvider } from "./context/productsContext";
import AppRoutes from "./routes/routes";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <div>
      <ProductsProvider>
        <AppRoutes />
        <Toaster />
      </ProductsProvider>
    </div>
  );
}

export default App;
