import { useEffect, useState } from "react";
import Menu from "./menu"
import { Link } from "react-router-dom";
import { Avatar } from "antd";
import EasyLinkEasyPay from "./easyLinkEasyPay/easyLinkEasyPay";

const HeaderSection = () => {

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);


    return (
        <>
            <div id="header" className="w-full relative bg-white">
                <video
                    className="absolute top-0 left-0 w-full h-full object-cover"
                    src="/videos/background-video.mp4"
                    autoPlay
                    loop
                    muted
                    controls={false}
                />

                {/* Overlay for dimming the video */}
                <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>

                <Menu isScrolled={isScrolled} />

                {/* main */}
                <div className="relative min-h-screen flex flex-col md:flex-row justify-between items-center px-4 md:px-20 xl:px-40 2xl:px-60 py-20">
                    <div className="2xl:grid xl:grid lg:grid md:grid grid-cols-2 gap-x-10 pt-20">
                        {/* left */}
                        <div>
                            <p className="text-white 2xl:mt-10 xl:mt-10 lg:mt-10 md:mt-10 text-[36px] md:text-[50.66px] font-normal font-['Imprima'] leading-tight md:leading-[63px]">
                                Providing confidence in every
                                <span className="text-[#552130] bg-white px-3 ml-3">transaction</span>
                            </p>

                            <p className="mt-10 text-lg md:text-xl font-semibold font-['Plus Jakarta Sans'] leading-7 text-white">
                                Ensuring trust in digital payments, prioritizing security and
                                transparency in every transaction, Peacemaker ensures that both
                                buyers and sellers can engage with peace of mind, knowing their
                                funds are protected and safeguarded at every step.
                            </p>

                            {/* CTA Buttons */}
                            <div className="flex gap-3 mt-10">
                                <div className="flex justify-center items-center px-[18px] py-2.5 bg-white rounded-lg shadow border border-[#cfd4dc]">
                                    <Link to="https://app.peacemaker.cash/">
                                        <div className="2xl:w-[143px] xl:w-[143px] lg:w-[143px] md:w-[143px] w-fit px-3 text-center text-[#344053] text-base font-semibold font-['Inter'] leading-normal">
                                            Log In
                                        </div>
                                    </Link>
                                </div>
                                <Link to="https://app.peacemaker.cash/">
                                    <div className="flex justify-center items-center px-[18px] py-2.5 bg-[#552130] rounded shadow border border-[#552130]">
                                        <div className="text-white text-base font-semibold font-['Inter'] leading-normal">
                                            Create an account
                                        </div>
                                    </div>
                                </Link>
                            </div>

                            {/* join 4000+ */}
                            <div className="2xl:mt-40 xl:mt-40 lg:mt-40 md:mt-40 mt-10 flex gap-5 items-center">
                                <div>
                                    <Avatar.Group
                                        size="large"
                                        max={{
                                            count: 3,
                                            style: { color: '#f56a00', backgroundColor: '#fde3cf', cursor: 'pointer' },
                                            popover: { trigger: 'click' },
                                        }}
                                    >
                                        <Avatar src="/images/user-1.png" />
                                        <Avatar src="/images/user-2.png" />
                                        <Avatar src="/images/user-3.png" />
                                    </Avatar.Group>
                                </div>

                                <div>
                                    <span className="text-zinc-300 text-lg font-normal font-['Plus Jakarta Sans'] leading-7">
                                        Join
                                    </span>
                                    <span className="text-[#552130] bg-white px-3 text-lg font-bold font-['Plus Jakarta Sans'] leading-normal">
                                        4000+ Individuals
                                    </span>
                                    <span className="text-zinc-200 text-lg font-normal font-['Plus Jakarta Sans'] leading-7">
                                        to send and receive money in Ghana and Nigeria
                                    </span>
                                </div>
                            </div>
                        </div>

                        {/* right */}
                        {/* easy link easy pay */}
                        <div className="w-full 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-0 mt-10 flex justify-end">
                            <EasyLinkEasyPay />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HeaderSection
