import React from "react";
import { Link } from "react-router-dom";

const FooterSection: React.FC = () => {
  return (
    <footer
      id="footer"
      className="w-full bg-white py-16 flex flex-col items-center"
    >
      <div className="w-full max-w-[1440px] px-4">
        <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center gap-12">
          {/* Logo and Introduction */}
          <div className="flex flex-col items-start">
            <a href="https://app.peacemaker.cash/">
              <div className="flex items-center mb-4">
                <img
                  className="w-[150px] h-[150px] relative"
                  src="/images/peacemakerLogo.png"
                  alt="Google Play"
                />
              </div>
            </a>

            <p className="text-[#667084] text-base font-normal">
              Transact online peacefully.
            </p>
          </div>

          {/* Footer Links */}
          <div className="grid grid-cols-2 md:grid-cols-3 gap-8 lg:gap-16 w-full lg:w-auto">
            {/* Product Links */}
            <div className="flex flex-col">
              <h3 className="text-[#667084] text-sm font-semibold mb-4">
                Product
              </h3>
              <ul className="space-y-2">
                <li className="text-[#475466] text-base font-semibold">
                  Overview
                </li>
                <li className="text-[#475466] text-base font-semibold">
                  Features
                </li>
                <li className="text-[#475466] text-base font-semibold flex items-center">
                  Solutions
                  <span className="ml-2 text-[#027947] text-xs bg-[#ebfdf2] px-2 py-0.5 rounded-2xl">
                    New
                  </span>
                </li>
              </ul>
            </div>

            {/* Company Links */}
            <div className="flex flex-col">
              <h3 className="text-[#667084] text-sm font-semibold mb-4">
                Company
              </h3>
              <ul className="space-y-2">
                <li className="text-[#475466] text-base font-semibold">
                  About us
                </li>
                <li className="text-[#475466] text-base font-semibold">
                  Careers
                </li>
                <li className="text-[#475466] text-base font-semibold">News</li>
                <li className="text-[#475466] text-base font-semibold">
                  Contact
                </li>
              </ul>
            </div>

            {/* Resources Links */}
            <div className="flex flex-col">
              <h3 className="text-[#667084] text-sm font-semibold mb-4">
                Resources
              </h3>
              <ul className="space-y-2">
                <li className="text-[#475466] text-base font-semibold">Blog</li>
                <li className="text-[#475466] text-base font-semibold">
                  Newsletter
                </li>
                <li className="text-[#475466] text-base font-semibold">
                  Help centre
                </li>
                <li className="text-[#475466] text-base font-semibold">
                  <Link to="/privacy">
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="mt-16 w-full h-px bg-[#eaecf0]"></div>

        <div className="mt-8 flex flex-col lg:flex-row justify-between items-center text-center lg:text-left">
          <p className="text-[#667084] text-base font-normal mb-4 lg:mb-0">
            © 2024 Peacemaker. All rights reserved.
          </p>
          <p className="text-[#0f1728] text-xl font-medium">
            Transact online peacefully.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default FooterSection;
