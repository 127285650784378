import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "../pages/home";
import EasyLinkEasyPay from "../pages/easyLinkEasyPay";
import Privacy from "../pages/privacy";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/:id" element={<EasyLinkEasyPay />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
