import React, { createContext, useContext, useState, ReactNode } from 'react';

interface Product {
    id: number;
    productName: string;
    description: string;
    quantity: number;
    amount: number;
}

interface ProductsContextType {
    products: Product[];
    addProduct: (product: Product) => void;
    editProduct: (id: number, updatedProduct: Product) => void;
    deleteProduct: (id: number) => void;
}

const ProductsContext = createContext<ProductsContextType | undefined>(undefined);

const ProductsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [products, setProducts] = useState<Product[]>([]);

    const addProduct = (product: Product) => {
        setProducts((prevProducts) => [...prevProducts, { ...product, id: Date.now() }]);
    };

    const editProduct = (id: number, updatedProduct: Product) => {
        setProducts((prevProducts) =>
            prevProducts.map((product) => (product.id === id ? { ...updatedProduct, id } : product))
        );
    };

    const deleteProduct = (id: number) => {
        setProducts((prevProducts) => prevProducts.filter((product) => product.id !== id));
    };

    return (
        <ProductsContext.Provider value={{ products, addProduct, editProduct, deleteProduct }}>
            {children}
        </ProductsContext.Provider>
    );
};

// Custom hook to use the ProductsContext
const useProducts = () => {
    const context = useContext(ProductsContext);
    if (context === undefined) {
        throw new Error('useProducts must be used within a ProductsProvider');
    }
    return context;
};

export { ProductsProvider, useProducts };
