import React from "react";

const HowSection = () => {
  return (
    <div id="how" className="border-b border-black/10">
      <section className="w-full max-w-screen-xl mx-auto px-4 py-12">
        <div className="w-full flex flex-col justify-start items-start gap-12">
          <div className="text-black text-4xl font-normal">
            How Peacemaker operates
          </div>
          <div className="text-black text-lg font-semibold max-w-3xl">
            Peacemaker simplifies secure online transactions. Buyers and sellers agree on terms, and payments are held in escrow until all obligations are met. Once verified, funds are released, ensuring trust and transparency for both parties.
          </div>

          <div className="2xl:flex xl:flex lg:flex md:flex flex-wrap justify-start items-start gap-8">
            {/* Row 1 */}
            <div className="2xl:flex xl:flex lg:flex md:flex flex-wrap w-full gap-8">
              {/* Card 1 */}
              <div className="flex-1 bg-[#552130]/10 p-6 rounded-2xl flex flex-col gap-6">
                <img
                  className="w-40 h-40"
                  src="/images/deal_terms.png"
                  alt="Google Play"
                />
                <div className="flex flex-col gap-4">
                  <div className="text-[#475467] text-2xl font-normal">
                    Both the buyer and seller <br />
                    consent to deal terms
                  </div>
                  <div className="text-black text-lg font-semibold">
                    Both parties need to agree on deal conditions such as
                  </div>
                  <ul className="text-[#475467] text-base font-semibold space-y-2">
                    <li>Deal closure (date and time)</li>
                    <li>Payment splits</li>
                    <li>Deal conditional notices</li>
                  </ul>
                </div>
              </div>

              {/* Card 2 */}
              <div className="flex-1 bg-[#f2d4ba] p-6 rounded-2xl flex flex-col gap-6 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-0 mt-5">
                <img
                  className="w-40 h-40"
                  src="/images/make_payment.png"
                  alt="Google Play"
                />
                <div className="flex flex-col gap-4">
                  <div className="text-[#475467] text-2xl font-normal">
                    The buyer makes the payment to initiate the deal
                  </div>
                  <div className="text-black text-lg font-semibold">
                    Make payment for either the service or the item
                  </div>
                </div>
              </div>
            </div>

            {/* Row 2 */}
            <div className="2xl:flex xl:flex lg:flex md:flex flex-wrap w-full gap-8 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-0 mt-5">
              {/* Card 3 */}
              <div className="flex-1 bg-[#c84e41]/90 p-6 rounded-2xl flex flex-col gap-6">
                <img
                  className="w-40 h-40"
                  src="/images/service_provision.png"
                  alt="Google Play"
                />
                <div className="flex flex-col gap-4">
                  <div className="text-white text-2xl font-normal">
                    The seller provides items or services to the buyer
                  </div>
                  <div className="text-white text-lg font-semibold">
                    Deal closure (date and time)
                  </div>
                </div>
              </div>

              {/* Card 4 */}
              <div className="flex-1 bg-[#3f3d56]/80 p-6 rounded-2xl flex flex-col gap-6 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-0 mt-5">
                <img
                  className="w-40 h-40"
                  src="/images/approval.png"
                  alt="Google Play"
                />
                <div className="flex flex-col gap-4">
                  <div className="text-white text-2xl font-normal">
                    Approval of items or services by the buyer
                  </div>
                  <div className="text-white text-lg font-semibold">
                    Buyer confirms the item or service purchased
                  </div>
                  <ul className="text-white text-base font-semibold space-y-2">
                    <li>Deal closure (date and time)</li>
                    <li>Payment splits</li>
                    <li>Deal conditional notices</li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Row 3 */}
            <div className="w-full 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-0 mt-5">
              {/* Card 5 */}
              <div className="bg-[#552130]/10 p-6 rounded-2xl flex flex-col gap-6 w-full">
                <img
                  className="w-40 h-40"
                  src="/images/fund_release.png"
                  alt="Google Play"
                />
                <div className="flex flex-col gap-4 text-right">
                  <div className="text-[#475467] text-2xl font-normal">
                    Peacemaker releasing funds to the seller
                  </div>
                  <div className="text-black text-lg font-semibold">
                    Both parties need to agree on deal conditions such as
                  </div>
                  <ul className="text-[#475467] text-base font-semibold space-y-2">
                    <li>Deal closure (date and time)</li>
                    <li>Payment splits</li>
                    <li>Deal conditional notices</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HowSection;
