import { Modal, Form, Input } from 'antd';
import { useProducts } from '../../../context/productsContext';
import { useForm } from 'antd/es/form/Form';


interface modalProps {
    isOpened: boolean;
    handleClose: () => void;
    selectedProduct: any;
}


const EditProductModal = ({ isOpened, handleClose, selectedProduct }: modalProps) => {

    const [form] = useForm();
    const { editProduct } = useProducts();


    const handleFormSubmit = (form_values: any) => {
        let editedProd = {
            id: selectedProduct?.id,
            productName: form_values?.productName,
            description: form_values?.description,
            quantity: Number(form_values?.quantity),
            amount: Number(form_values?.amount)
        }
        console.log("___editedProd:", editedProd);

        editProduct(selectedProduct?.id, editedProd);
        form.resetFields();
        handleClose();
    };

    //initialValues
    const initialValues = {
        productName: selectedProduct?.productName,
        description: selectedProduct?.description,
        quantity: selectedProduct?.quantity,
        amount: selectedProduct?.amount
    };

    return (
        <>
            <Modal
                title="Edit Product"
                open={isOpened}
                onCancel={handleClose}
                footer={null}
                maskClosable={false}
                keyboard={false}
                centered
            >
                <hr />
                <Form
                    form={form}
                    className='mt-5'
                    layout="vertical"
                    onFinish={handleFormSubmit}
                    initialValues={initialValues}
                >
                    <Form.Item
                        label="Product Name"
                        name="productName"
                        rules={[{ required: true, message: 'Please enter the product name' }]}
                    >
                        <Input className='h-[40px]' placeholder="Enter product name" />
                    </Form.Item>

                    <Form.Item
                        label="Description"
                        name="description"
                        rules={[{ required: true, message: 'Please enter the description' }]}
                    >
                        <Input.TextArea rows={3} placeholder="Enter description" />
                    </Form.Item>

                    <div className="grid grid-cols-2 gap-5">
                        <Form.Item
                            label="Quantity"
                            name="quantity"
                            rules={[{ required: true, message: 'Please enter the quantity' }]}
                        >
                            <Input className='h-[40px]' type="number" placeholder="Enter quantity" />
                        </Form.Item>

                        <Form.Item
                            label="Amount"
                            name="amount"
                            rules={[{ required: true, message: 'Please enter the amount' }]}
                        >
                            <Input className='h-[40px]' type="number" placeholder="Enter amount" />
                        </Form.Item>
                    </div>

                    <Form.Item>
                        <button className='h-[50px] w-full bg-[#552130] text-white rounded-[5px]'>
                            Update
                        </button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default EditProductModal;
