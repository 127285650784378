import { useState } from 'react';
import easyImage from '../../assets/easylinkImg.png';
import PhoneNumberInput from "react-phone-number-input";
import { IoChevronBackOutline } from "react-icons/io5";
import { GiPencil } from "react-icons/gi";
import { IoTrashOutline } from "react-icons/io5"
import { CiCirclePlus } from "react-icons/ci";
import { message, Spin, Tooltip } from 'antd';
import { motion } from "framer-motion";
import AddProductModal from './modal/addProductModal';
import { useProducts } from '../../context/productsContext';
import EditProductModal from './modal/editProductModal';
import axios from 'axios';
import { BASE_URL } from '../../helpers/constants';
import DataTable from 'react-data-table-component';
import { LoadingOutlined } from '@ant-design/icons';


const EasyLinkEasyPay = () => {

    const [isBusy, setIsBusy] = useState(false);
    const { products, deleteProduct } = useProducts();
    const [activeStep, setActiveStep] = useState(0);
    //modal
    const [openAddModal, setOpenAddModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<any>(null);

    const [phoneNumber, setPhoneNumber] = useState<any>("");
    const [email, setEmail] = useState<any>("");
    const [theOTP, setTheOTP] = useState<any>("")

    const [theEasyLink, setTheEasyLink] = useState<any>("");

    const [selectedRows, setSelectedRows] = useState<any>([]);

    //handleSubmitPhoneAndEmail
    const handleSubmitPhoneAndEmail = () => {
        if (!phoneNumber) {
            message.info('Please enter your phone number');
        } else if (!email) {
            message.info('Please enter your email');
        } else {
            let formattedPhoneNumber = phoneNumber?.replace('+233', '+233 ');
            setIsBusy(true);
            var config = {
                method: 'post',
                url: `${BASE_URL}/api/v1/elep/otp`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: { "phone_number": formattedPhoneNumber }
            };

            axios(config)
                .then((response) => {
                    console.log(response?.data);
                    message.success('OTP sent successfully to your phone number');
                    setIsBusy(false);
                    setActiveStep(2);
                })
                .catch((error: any) => {
                    console.log(error);
                    message.error(error?.response?.data?.data || 'An error occurred. Please try again');
                    setIsBusy(false);
                });
        }
    }

    //handleOtpVerification
    const handleOtpVerification = () => {
        if (!theOTP) {
            message.info('Please enter the OTP sent to your phone number');
        } else {
            let data = {
                "phone_number": phoneNumber?.replace('+233', '+233 '),
                "otp": theOTP
            };
            setIsBusy(true);
            var config = {
                method: 'post',
                url: `${BASE_URL}/api/v1/elep/verify`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then((response) => {
                    console.log(response?.data);
                    message.success(response?.data?.data || 'OTP verified successfully');
                    setIsBusy(false);
                    setActiveStep(3);
                })
                .catch((error: any) => {
                    console.log(error);
                    message.error(error?.response?.data?.data || 'An error occurred. Please try again');
                    setIsBusy(false);
                });
        }
    }

    //handleSaveProducts
    const handleSaveProducts = () => {
        if (products?.length === 0) {
            message.info('Please add at least one product to continue');
            return;
        }

        console.log('Products Saved');
        setActiveStep(4);
    }

    //handleGetLink
    const handleGetLink = () => {
        if (products?.length === 0) {
            message.info('Please add at least one product to continue');
        } else {
            let data = {
                "phone_number": phoneNumber?.replace('+233', '+233 '),
                "email": email,
                "charge_self": true,
                "items": selectedRows?.length === 0 ? products?.map((item: any) => (
                    {
                        "name": item?.productName,
                        "price": Number(item?.amount),
                        "qty_available": Number(item?.quantity),
                        "description": item?.description,
                        "is_tangible": true
                    }
                ))
                    : selectedRows?.map((item: any) => (
                        {
                            "name": item?.productName,
                            "price": Number(item?.amount),
                            "qty_available": Number(item?.quantity),
                            "description": item?.description,
                            "is_tangible": true
                        }
                    )),
                "is_verified": true
            }
            console.log("__dataToSend:", data)

            setIsBusy(true);
            var config = {
                method: 'post',
                url: `${BASE_URL}/api/v1/elep/new`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then((response) => {
                    console.log(response?.data);
                    message.success('Your link has been generated successfully');
                    setTheEasyLink(response?.data?.data);
                    setIsBusy(false);
                    setActiveStep(5);
                })
                .catch((error: any) => {
                    console.log(error);
                    message.error(error?.response?.data?.data || 'An error occurred. Please try again');
                    setIsBusy(false);
                });
        }
    }

    //handleCopyLinkToClipboard
    const handleCopyLink = () => {
        if (theEasyLink) {
            const textArea = document.createElement("textarea");
            textArea.value = theEasyLink;
            document.body.appendChild(textArea);

            textArea.select();
            document.execCommand("copy");
            document.body.removeChild(textArea);

            message.info("Link was copied to clipboard");
        } else {
            message.warning('No link to copy');
        }
    }

    // table columns
    const columns = [
        {
            name: 'Name',
            cell: (row: any) => (
                <div>
                    <span>{row?.productName}</span>
                    <p className="text-xs text-gray-500">{row?.description}</p>
                </div>
            ),
        },
        {
            name: 'Qty',
            selector: (row: any) => row?.quantity,
            center: true,
        },
        {
            name: 'Price',
            selector: (row: any) => row?.amount,
            center: true,
        },
    ];

    //allSteps
    const allSteps = [
        //step1 start
        <motion.div
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 100 }}
            transition={{ duration: 1.0 }}>
            <div className='relative h-[600px]'>
                <img className='flex mr-auto' src={easyImage} alt="" />

                <div className="mt-[48px]">
                    <p className='text-[36px] font-[600] text-white'>Easy Link, Easy Pay</p>

                    <p className='text-[14px] font-[500] text-[#D9D9D9] mt-[18px]'>
                        Make buying Safe and easy for your customers. Create and share a secure payment link for your products and services. PeaceMaker will handle the transactions for you.
                    </p>
                </div>

                <div className='absolute bottom-[70px] z-10'>
                    <button className='w-fit text-white scale-100 hover:scale-105' onClick={() => setActiveStep(1)}>
                        <p className='text-[16px] font-[600]'>Generate my link</p>
                    </button>
                </div>
            </div>
        </motion.div>,
        //step2 phone and email
        <div className='relative h-[600px]'>
            <p className='text-[36px] font-[600] text-white'>Let's get you verified</p>
            <p className='text-[14px] font-[500] text-white mt-[18px]'>
                Please enter your phone number and email to get verified
            </p>

            <div className="mt-[50px]">
                <p className='text-[14px] font-[500] text-white mb-[7px]'>Phone number</p>
                <PhoneNumberInput
                    value={phoneNumber}
                    onChange={(value) => setPhoneNumber(value)}
                    defaultCountry="GH"
                    countryOptionsOrder={["GH", "NG"]}
                    className="w-full bg-[#E4E4E4] border border-[#3131311A] rounded-[6px] p-[10px]"
                    numberInputProps={{
                        className: "focus:outline-none bg-[#E4E4E4] w-full",
                        placeholder: "Enter your phone number"
                    }}
                />
                <p className='text-[14px] font-[500] text-white mt-[7px]'>Phone number has to be linked to your momo</p>

                <div className="mt-[20px]">
                    <p className='text-[14px] font-[500] text-white mb-[7px]'>Email</p>
                    <input
                        placeholder='Enter your email'
                        type='email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className='w-full bg-[#E4E4E4] border border-[#3131311A] rounded-[6px] p-[10px] focus:outline-none'
                    />
                </div>
            </div>

            <div className='absolute bottom-[70px] z-10 flex gap-[20px] items-center'>
                <div>
                    <button className='h-[36px] w-[36px] rounded-full bg-white flex justify-center items-center disabled:cursor-not-allowed' onClick={() => setActiveStep(0)} disabled={isBusy}>
                        <IoChevronBackOutline className='h-[20px] w-[20px]' />
                    </button>
                </div>
                <div>
                    <button className='w-fit text-white scale-100 hover:scale-105 disabled:cursor-not-allowed' onClick={handleSubmitPhoneAndEmail} disabled={isBusy}>
                        <p className='text-[16px] font-[600]'>Continue</p>
                    </button>
                </div>
            </div>
        </div>,
        //step3 otp verification
        <div className='relative h-[600px]'>
            <p className='text-[36px] font-[600] text-white'>OTP Verification</p>
            <p className='text-[14px] font-[500] text-white mt-[18px]'>
                Please enter the code sent to {phoneNumber || 'your phone number'}
            </p>

            <div className="mt-[50px]">
                <p className='text-[14px] font-[500] text-white mb-[7px]'>Code</p>
                <input
                    placeholder='Enter otp code'
                    type='text'
                    value={theOTP}
                    onChange={(e) => setTheOTP(e.target.value)}
                    className='w-full bg-[#E4E4E4] border border-[#3131311A] rounded-[6px] p-[10px] focus:outline-none'
                />
            </div>

            <div className='absolute bottom-[70px] z-10 flex gap-[20px] items-center'>
                <div>
                    <button className='h-[36px] w-[36px] rounded-full bg-white flex justify-center items-center disabled:cursor-not-allowed' disabled={isBusy} onClick={() => setActiveStep(1)}>
                        <IoChevronBackOutline className='h-[20px] w-[20px]' />
                    </button>
                </div>
                <div>
                    <button className='w-fit text-white scale-100 hover:scale-105 disabled:cursor-not-allowed' disabled={isBusy} onClick={handleOtpVerification}>
                        <p className='text-[16px] font-[600]'>Continue</p>
                    </button>
                </div>
            </div>
        </div>,
        //step4 add products
        <div className='relative h-[600px]'>
            <p className='text-[36px] font-[600] text-white'>Let's add your products</p>
            <div className="flex justify-between items-center mt-[18px]">
                <p className='text-[14px] font-[500] text-white'>
                    Fill the form below to list your products
                </p>

                <Tooltip title='Add Product'>
                    <button className='h-[36px] w-[36px] text-white' onClick={() => setOpenAddModal(true)}>
                        <CiCirclePlus size={24} />
                    </button>
                </Tooltip>
            </div>

            {/* table Name, Qty, Price */}
            <div className="mt-[30px]">
                <table className="w-full border-collapse">
                    <thead className="bg-gray-800">
                        <tr>
                            <th className="text-[14px] font-[500] text-white py-2 border-b border-gray-600 text-left pl-3">Name</th>
                            <th className="text-[14px] font-[500] text-white py-2 text-center border-b border-gray-600">Qty</th>
                            <th className="text-[14px] font-[500] text-white py-2 text-center border-b border-gray-600">Price</th>
                            <th className="text-[14px] font-[500] text-white py-2 text-center border-b border-gray-600"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            products?.map((product: any, index: number) => (
                                <tr key={index} className="border-b hover:bg-gray-600 border-gray-600">
                                    <td className="text-[14px] font-[500] text-white py-3 pl-3">
                                        {product?.productName}
                                        <p className='text-xs text-gray-300 line-clamp-1'>{product?.description}</p>
                                    </td>
                                    <td className="text-[14px] font-[500] text-white py-3 text-center">{product?.quantity}</td>
                                    <td className="text-[14px] font-[500] text-white py-3 text-center">{product?.amount}</td>
                                    <td className="text-[14px] font-[500] text-white py-3 text-center">
                                        <Tooltip title='Edit Product'>
                                            <button
                                                onClick={() => {
                                                    setSelectedProduct(product);
                                                    setOpenEditModal(true);
                                                }}
                                                className="mr-3"
                                            >
                                                <GiPencil size={18} />
                                            </button>
                                        </Tooltip>

                                        <Tooltip title='Delete Product'>
                                            <button
                                                onClick={() => deleteProduct(product?.id)}
                                            >
                                                <IoTrashOutline size={18} />
                                            </button>
                                        </Tooltip>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>

            <div className='absolute bottom-[70px] z-10 flex gap-[20px] items-center'>
                <div>
                    <button className='h-[36px] w-[36px] rounded-full bg-white flex justify-center items-center' onClick={() => setActiveStep(1)}>
                        <IoChevronBackOutline className='h-[20px] w-[20px]' />
                    </button>
                </div>
                <div>
                    <button className='w-fit text-white scale-100 hover:scale-105' onClick={handleSaveProducts}>
                        <p className='text-[16px] font-[600]'>Continue</p>
                    </button>
                </div>
            </div>
        </div>,
        //step5 get link
        <div className='relative h-[600px]'>
            <p className='text-[36px] font-[600] text-white'>Deal overview</p>
            <p className='text-[14px] font-[500] text-white mt-[18px]'>
                Please review the details of your deal
            </p>

            {/* table Name, Qty, Price */}
            <div className="mt-[30px]">
                <DataTable
                    columns={columns}
                    data={products}
                    selectableRows
                    onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows)}
                    highlightOnHover
                />

                <p className='text-[14px] font-[500] text-white mb-[7px] mt-[16px]'>Confirm payment number</p>
                <PhoneNumberInput
                    value={phoneNumber}
                    onChange={(value) => setPhoneNumber(value)}
                    defaultCountry="GH"
                    countryOptionsOrder={["GH", "NG"]}
                    className="w-full bg-[#E4E4E4] border border-[#3131311A] rounded-[6px] p-[10px] hover:cursor-not-allowed"
                    numberInputProps={{
                        className: "focus:outline-none bg-[#E4E4E4] w-full hover:cursor-not-allowed",
                        placeholder: "Enter your phone number"
                    }}
                    disabled
                />
            </div>

            <div className='absolute bottom-[70px] z-10 flex gap-[20px] items-center'>
                <div>
                    <button className='h-[36px] w-[36px] rounded-full bg-white flex justify-center items-center disabled:cursor-not-allowed' disabled={isBusy} onClick={() => setActiveStep(3)}>
                        <IoChevronBackOutline className='h-[20px] w-[20px]' />
                    </button>
                </div>
                <div>
                    <button className='w-fit text-white scale-100 hover:scale-105 disabled:cursor-not-allowed' disabled={isBusy} onClick={handleGetLink}>
                        <p className='text-[16px] font-[600]'>Get Link</p>
                    </button>
                </div>
            </div>
        </div>,
        //step6 copy link
        <div className='relative h-[600px]'>
            <p className='text-[36px] font-[600] text-white'>Link Generated</p>
            <p className='text-[14px] font-[500] text-white mt-[18px]'>
                Your link has been generated. Share it with your customers
            </p>

            {/* link */}
            <div className="mt-[30px]">
                <div className=" border border-[#3131311A] rounded-[6px] p-[10px] text-white bg-black bg-opacity-10">
                    {theEasyLink}
                </div>

                {/* copy */}
                <div className="flex justify-end mt-5">
                    <button className='w-fit text-white scale-100 hover:scale-105 mt-[10px]' onClick={handleCopyLink}>
                        <p className='text-[16px] font-[600]'>Copy Link</p>
                    </button>
                </div>
            </div>

            <div className='absolute bottom-[70px] z-10'>
                <div>
                    <button className='w-fit text-white scale-100 hover:scale-105' onClick={() => setActiveStep(0)}>
                        <p className='text-[16px] font-[600]'>Finished</p>
                    </button>
                </div>
            </div>
        </div>
    ];



    return (
        <>
            <div className="2xl:w-[500px] xl:w-[500px] lg:w-[500px] md:w-[500px] w-full border border-[#FFFFFF1A] bg-[#D9D9D915] h-[600px] shadow-md rounded-[36px] p-[36px] relative">

                {/* loading */}
                {
                    isBusy && <div className='flex justify-center items-center h-full w-full rounded-[36px] bg-black bg-opacity-80 z-50 absolute right-0 bottom-0'>
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 48, color: '#fff' }} spin />} />
                    </div>
                }

                {/* content */}
                <div>
                    {allSteps[activeStep]}
                </div>

                {/* footer */}
                <div className="2xl:absolute xl:absolute lg:absolute md:absolute 2xl:block xl:block lg:block md:block hidden bottom-[36px] 2xl:w-[430px] xl:w-[430px] lg:w-[430px] md:w-[430px]">
                    <div className="flex justify-end 2xl:w-[430px] xl:w-[430px] lg:w-[430px] md:w-[430px]">
                        <div className='flex items-center gap-[4px]'>
                            {
                                Array.from({ length: allSteps.length }, (_, index) => (
                                    <div
                                        key={index}
                                        className={`w-[12px] h-[12px] rounded-full mx-1 ${activeStep === index ? 'bg-[#552130]' : 'bg-[#D9D9D9]'}`}
                                    ></div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>


            {/* Add Product Modal */}
            {
                openAddModal && <AddProductModal isOpened={openAddModal} handleClose={() => setOpenAddModal(false)} />
            }

            {/* Edit Product Modal */}
            {
                openEditModal && <EditProductModal isOpened={openEditModal} handleClose={() => setOpenEditModal(false)} selectedProduct={selectedProduct} />
            }
        </>
    )
}

export default EasyLinkEasyPay
