import React, { useState } from "react";

interface CountryData {
  name: string;
  dealers: string;
  additionalDealers: string;
  flag: string;
  images: string[];
}

const BranchSection: React.FC = () => {
  const [selectedCountry, setSelectedCountry] = useState<string>("Ghana");

  const countries: { [key: string]: CountryData } = {
    Ghana: {
      name: "Ghana",
      dealers: "4000 + Dealers",
      additionalDealers: "500 + Dealers",
      flag: "/images/ghana_flag.png",
      images: [
        "/images/user-1.png",
        "/images/user-2.png",
        "/images/user-3.png",
      ],
    },
    Nigeria: {
      name: "Nigeria",
      dealers: "5000 + Dealers",
      additionalDealers: "600 + Dealers",
      flag: "/images/nigerian_flag.png",
      images: [
        "/images/user-1.png",
        "/images/user-2.png",
        "/images/user-3.png",
      ],
    },
  };

  const handleFlagClick = (country: string) => {
    setSelectedCountry(country);
  };

  return (
    <section
      id="branches"
      className="w-full h-full py-12 border-b border-black/10"
    >
      <div className="w-full max-w-screen-xl mx-auto flex flex-wrap justify-between items-start gap-7 px-4 py-12">
        <div className="w-full lg:w-6/12 flex flex-col justify-start items-start gap-[47px]">
          <div className="relative w-full h-[134px]">
            <div className=" text-black text-[50.66px] font-normal font-['Imprima'] leading-[66px]">
              Trade within countries with <span className=" text-[#c84e41] text-[50.66px] font-normal font-['Imprima'] leading-[66px]">
                Peacemaker
              </span>
            </div>

          </div>
          <div className="flex flex-col gap-[37px] mt-5">
            <div className="text-black text-lg font-semibold font-['Plus Jakarta Sans'] leading-7">
              We believe that trade should be a catalyst for growth and
              prosperity, not a source of conflict. That's why we've developed
              Peacemaker, a secure and efficient escrow-like payment solution
              designed specifically for intra-country trade. With Peacemaker,
              businesses and individuals can conduct transactions with
              confidence, knowing that their funds are protected and their
              interests are safeguarded.
            </div>
          </div>
        </div>

        <div className="2xl:w-5/12 xl:w-5/12 lg:w-5/12 md:w-5/12 w-[100px] relative h-[474px] 2xl:block xl:block lg:block md:block hidden">
          <div className="absolute top-0 left-[60px] w-[440px] h-[474px]">
            <img
              className="absolute top-0 left-0 2xl:w-[440px] xl:w-[440px] lg:w-[440px] md:w-[440px] w-[350px] h-[474px]"
              src="/images/africa.png"
              alt="African Map"
            />
          </div>
          <div className="absolute top-[83px] left-0 w-[318.42px] h-[301px]">
            <div className="absolute top-[4.86px] left-[272.02px] flex flex-col gap-2">
              <div
                className="flex items-center gap-4 p-[12.89px] bg-white rounded-[12.80px] cursor-pointer"
                onClick={() => handleFlagClick("Ghana")}
              >
                <img
                  className="w-[20.62px] h-[20.62px]"
                  src="/images/ghana_flag.png"
                  alt="Ghana Flag"
                />
              </div>
              <div
                className="flex items-center gap-4 p-[12.89px] bg-white rounded-[12.80px] cursor-pointer"
                onClick={() => handleFlagClick("Nigeria")}
              >
                <img
                  className="w-[20.62px] h-[20.62px]"
                  src="/images/nigerian_flag.png"
                  alt="Nigeria Flag"
                />
              </div>
            </div>
            <div className="absolute top-[-0.07px] left-0 flex flex-col gap-[24.66px] p-[34.68px] bg-white rounded-[25.50px] shadow border border-black/10">
              <div className="flex items-center gap-[23.89px] px-[19.27px] py-[11.56px] bg-[#552130]/5 rounded-[107.90px]">
                <img
                  className="w-[30.83px] h-[30.83px]"
                  src={countries[selectedCountry].flag}
                  alt={`${countries[selectedCountry].name} Flag`}
                />
                <div className="text-black text-lg font-normal font-['Imprima'] leading-[50.87px]">
                  {countries[selectedCountry].name}
                </div>
              </div>
              <div className="flex flex-col gap-[26.98px]">
                <div className="text-black text-[27.75px] font-normal font-['Imprima'] leading-[50.87px]">
                  {countries[selectedCountry].dealers}
                </div>
                <div className="relative h-[68.24px]">
                  <div className="absolute top-[66.24px] left-0 text-zinc-900 text-sm font-bold font-['Plus Jakarta Sans'] leading-snug">
                    {countries[selectedCountry].additionalDealers}
                  </div>
                  <div className="flex w-[120px] h-[53px] left-0 top-0 absolute">
                    {countries[selectedCountry].images.map((imgSrc, idx) => (
                      <img
                        key={idx}
                        className="w-[53px] h-[53px] rounded-full border-2 border-white"
                        src={imgSrc}
                        alt={`Dealer ${idx + 1}`}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BranchSection;
