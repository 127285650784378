import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const Privacy = () => {
    return (
        <>
            <div className="w-full 2xl:px-[120px] xl:px-[100px] lg:px-[80px] md:px-[80px] px-[20px] py-10">
                <motion.div
                    initial={{ opacity: 0, x: 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 100 }}
                    transition={{ duration: 1.0 }}>
                    <div className="w-full">
                        <Link to="/">
                            <img src="/images/peacemakerLogo.png" alt="PeaceMaker Logo" />
                        </Link>

                        <p className="text-[28px] font-[600] text-[#1B1D1F] dark:text-dark_text">Privacy Policy</p>

                        <div className="mt-5">
                            <p className="text-[16px] font-[500] text-[#1B1D1F] dark:text-dark_text">PeaceMaker Privacy Policy</p>

                            <p className="text-[14px] font-[400] text-[#1B1D1F] dark:text-dark_text">
                                PeaceMaker's Privacy Policy applies to personal information collected and/or held by <span>
                                    <Link to="/" className="text-blue-500 dark:text-dark_text underline">peacemaker.com</Link>
                                </span>
                            </p>

                            <p className="text-[14px] font-[600] text-[#1B1D1F] dark:text-dark_text mt-3">
                                Types of Personal Information We Collect and Hold:
                            </p>

                            <p className="text-[14px] font-[400] text-[#1B1D1F] dark:text-dark_text">
                                We collect personal information about our users to provide our products, services, and customer support. Not all information requested, collected, and processed by us is “Personal Information” as it does not identify you as a specific natural person. This includes the majority of “User Generated Content” that you provide with the intention of sharing with other users inside a transaction. Such “Non-Personal Information” is not covered by this privacy policy. However, as non-personal information may be used collectively or linked with existing personal information, it can be treated as personal information.
                            </p>

                            <p className="text-[14px] font-[600] text-[#1B1D1F] dark:text-dark_text mt-3">
                                How We Collect Personal Information
                            </p>

                            <p className="text-[14px] font-[500] text-[#1B1D1F] dark:text-dark_text">
                                Information You Specifically Give Us:
                            </p>

                            <p className="text-[14px] font-[400] text-[#1B1D1F] dark:text-dark_text mt-2">
                                While you use our products and services, you may be asked to provide certain types of personal information. This might happen through our website, applications, online chat systems, telephone, paper forms, or in-person meetings. We will provide notice at the time, to explain how we will use the personal information we are requesting. The notice may be written or verbal. We may request, collect, or process the following information:
                            </p>

                            <ul className="list-disc pl-6 text-[14px] font-[400] text-[#1B1D1F] dark:text-dark_text">
                                <li>Contact Details - email address (Optional), phone number</li>
                                <li>Identity Details - full name</li>
                                <li>Financial information (where applicable)</li>
                                <li>Financial Information - where applicable</li>
                            </ul>

                            <p className="text-[14px] font-[500] text-[#1B1D1F] dark:text-dark_text mt-3">
                                Information We Collect as You Use Our Service
                            </p>

                            <p className="text-[14px] font-[400] text-[#1B1D1F] dark:text-dark_text">
                                We maintain records of the interactions we have with our users, including the products, services, and customer support provided. This includes the interactions users have with our platform, such as when a user views a page or clicks a button.
                            </p>

                            <p className="text-[14px] font-[400] text-[#1B1D1F] dark:text-dark_text">
                                When contacted, we may collect personal information intrinsic to the communication. For example, if contacted via email, we will collect the email address used. <br />
                                We may collect or process the following information:
                            </p>

                            <ul className="list-disc pl-6 text-[14px] font-[400] text-[#1B1D1F] dark:text-dark_text">
                                <li>Metadata - IP address, computer and connection information, referring web page, standard web log information, language settings, timezone, etc</li>
                                <li>Device Information - device identifier, device type, device plugins, hardware capabilities, etc.</li>
                                <li>Actions - pages viewed, buttons clicked, time spent viewing, search keywords, etc.</li>
                            </ul>

                            <p className="text-[14px] font-[600] text-[#1B1D1F] dark:text-dark_text mt-3">
                                Links to Other Sites
                            </p>

                            <p className="text-[14px] font-[400] text-[#1B1D1F] dark:text-dark_text">
                                On our website, you may encounter links to third-party websites. These links may be from us or may appear as content generated by other users. These linked sites are not under our control, and thus we are not responsible for their actions. Before providing your personal information via any other website, we advise you to examine the terms and conditions of using that website and its privacy policy.
                            </p>

                            <p className="text-[14px] font-[600] text-[#1B1D1F] dark:text-dark_text mt-3">
                                How We Use Personal Information
                            </p>

                            <p className="text-[14px] font-[400] text-[#1B1D1F] dark:text-dark_text">
                                The information we request, collect, and process is primarily used to provide users with the product or service they have requested. More specifically, we may use your personal information for the following purposes:
                            </p>

                            <ul className="list-disc pl-6 text-[14px] font-[400] text-[#1B1D1F] dark:text-dark_text">
                                <li>To provide the service or product you have requested;</li>
                                <li>To facilitate the creation of PeaceMaker Agreements;</li>
                                <li>To provide technical or other support to you;</li>
                                <li>To answer inquiries about our services, or to respond to a complaint;</li>
                                <li>To promote our other programs, products, or services which may be of interest to you (unless you have opted out of such communications);</li>
                                <li>To conduct data analysis, research, and otherwise build and improve our platforms;</li>
                                <li>To comply with legal and regulatory obligations;</li>
                                <li>For other purposes with your consent, unless you withdraw your consent for these purposes.</li>
                            </ul>

                            <p className="text-[14px] font-[400] text-[#1B1D1F] dark:text-dark_text">
                                The 'lawful processing' grounds on which we will use personal information about our users are (but are not limited to):
                            </p>

                            <ul className="list-disc pl-6 text-[14px] font-[400] text-[#1B1D1F] dark:text-dark_text">
                                <li>When a user has given consent;</li>
                                <li>When necessary for the performance of a contract to which the user is a party;</li>
                                <li>Processing is necessary for compliance with our legal obligations;</li>
                                <li>Processing is necessary to protect the vital interests of our users or another natural person;</li>
                                <li>Processing is done in pursuit of our legitimate interests, where these interests do not infringe on the rights of our users.</li>
                            </ul>

                            <p className="text-[14px] font-[600] text-[#1B1D1F] dark:text-dark_text mt-3">
                                When We Disclose Personal Information
                            </p>
                            <p className="text-[14px] font-[600] text-[#1B1D1F] dark:text-dark_text mt-3">
                                Our Third-Party Service Providers
                            </p>

                            <p className="text-[14px] font-[400] text-[#1B1D1F] dark:text-dark_text">
                                The personal information of users may be held or processed on our behalf by our third-party service providers. These providers are bound by contract to only use your personal information on our behalf, under our instructions. Our third-party service providers include:
                            </p>

                            <ul className="list-disc pl-6 text-[14px] font-[400] text-[#1B1D1F] dark:text-dark_text">
                                <li>Cloud hosting, storage, networking, and related providers</li>
                                <li>SMS providers</li>
                                <li>Payment and banking providers</li>
                                <li>Marketing and analytics providers</li>
                                <li>Security providers</li>
                                <li>Chat providers</li>
                                <li>Email providers</li>
                            </ul>

                            <p className="text-[14px] font-[600] text-[#1B1D1F] dark:text-dark_text mt-3">
                                Other Disclosures and Transfers
                            </p>

                            <p className="text-[14px] font-[400] text-[#1B1D1F] dark:text-dark_text mt-3">
                                We may also disclose your personal information to third parties for the following purposes:
                            </p>

                            <ul className="list-disc pl-6 text-[14px] font-[400] text-[#1B1D1F] dark:text-dark_text">
                                <li>If necessary, to provide the service or product you have requested;</li>
                                <li>To comply with court orders, subpoenas, or other requests for information by law enforcement;</li>
                                <li>If otherwise permitted or required by law; or</li>
                                <li>For other purposes with your consent.</li>
                            </ul>

                            <p className="text-[14px] font-[400] text-[#1B1D1F] dark:text-dark_text mt-3">
                                If you have any questions regarding this privacy policy, you may contact us.
                            </p>
                        </div>
                    </div>
                </motion.div>
            </div>
        </>
    );
}

export default Privacy;
