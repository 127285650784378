import { Modal } from 'antd';
import { useNavigate } from 'react-router-dom';

interface ModalProps {
    isOpened: boolean;
    handleClose: () => void;
}

const PaymentSteps = ({ isOpened, handleClose }: ModalProps) => {

    const navigate = useNavigate();

    //handleOnClose
    const handleOnClose = () => {
        navigate("/");
        handleClose();
    }

    return (
        <>
            <Modal
                title="Payment Approval Steps"
                open={isOpened}
                onCancel={handleOnClose}
                footer={null}
                maskClosable={false}
                keyboard={false}
                centered
            >
                <hr />
                <div className='mt-5'>
                    <p>
                        Please check your mobile device for a payment approval request and approve it to proceed.
                        Click on <strong>"Completed" </strong>after approving payment.
                    </p>

                    <h4 className='mt-6 font-[600]'>Can't see the pop-up on your device?</h4>
                    <ul>
                        <li>
                            1. Dial the USSD code for your mobile network provider. (Example: *170# for MTN users)
                        </li> <br />
                        <li>
                            2. Navigate to the <strong>"My Approvals"</strong> section.
                        </li> <br />
                        <li>
                            3. Look for the pending payment request and approve it manually.
                        </li>
                    </ul> <br />
                    <p>
                        If you need further assistance, please contact our support team.
                    </p>

                    <div className="mt-5 flex justify-end">
                        <button className='w-fit h-[40px] px-5 bg-[#552130] text-white rounded' onClick={handleOnClose}>
                            Completed
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default PaymentSteps;
